<template>
    <div style="display:flex;flex-direction:column;height: 100%;" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <div v-if="active == 0"><van-empty description="暂时不支持手机端操作" /></div>
        <div v-else style="display:flex;flex-direction:column;height: 100%;">
            <van-sticky>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="option1Value" :options="option1" @change="handleChange"></van-dropdown-item>
                    <van-dropdown-item v-model="option2Value" :options="option2" @change="handleChange"></van-dropdown-item>
                </van-dropdown-menu>
            </van-sticky>
            <van-list v-model="listLoading" :finished="listFinished" finished-text="没有更多了" @load="getList">
                <div v-for="(item, index) in listData" :key="index" class="card" ref="listDiv">
                    <van-swipe-cell :before-close="deleteMateriel" :name="index">
                        <van-cell-group>
                            <template v-if="option1Value == 0">
                                <van-cell title="实验日期" :value="item.testTime"></van-cell>
                                <van-cell title="温度" :value="`${item.temperature} ℃`"></van-cell>
                                <van-cell title="水灰比" :value="`${item.shuiHuiBi} %`"></van-cell>
                                <van-cell title="电压" :value="`${item.dianYa} mV`"></van-cell>
                                <van-cell title="浓度" :value="`${item.nongDu} mol/L`"></van-cell>
                                <van-cell title="百分比" :value="`${item.baiFenBi} %`"></van-cell>
                            </template>
                            <template v-else-if="option1Value == 1">
                                <van-cell title="实验日期" :value="item.testTime"></van-cell>
                                <van-cell title="温度" :value="`${item.temperature} ℃`"></van-cell>
                                <van-cell title="质量" :value="`${item.zhiLiang} kg`"></van-cell>
                                <van-cell title="体积" :value="`${item.tiJi} mL`"></van-cell>
                                <van-cell title="电压" :value="`${item.dianYa} mV`"></van-cell>
                                <van-cell title="浓度" :value="`${item.nongDu} mol/L`"></van-cell>
                                <van-cell title="百分比" :value="`${item.baiFenBi} %`"></van-cell>
                            </template>
                            <template v-else-if="option1Value == 2">
                                <van-cell title="实验日期" :value="item.testTime"></van-cell>
                                <van-cell title="胶凝用量(kg)" :value="`${item.jiaoNingZhongLiang} kg`"></van-cell>
                                <van-cell title="砂用量(kg)" :value="`${item.shaZhongLiang} kg`"></van-cell>
                                <van-cell title="用水量(kg)" :value="`${item.shuiZhongLiang} kg`"></van-cell>
                                <van-cell title="水泥用量(kg)" :value="`${item.shuiNiZhongLiang} kg`"></van-cell>
                                <van-cell title="温度(℃)" :value="`${item.temperature} ℃`"></van-cell>
                                <van-cell title="氯离子质量(kg)" :value="`${item.zhiLiang} kg`"></van-cell>
                                <van-cell title="电压" :value="`${item.dianYa} mV`"></van-cell>
                                <van-cell title="浓度" :value="`${item.nongDu} mol/L`"></van-cell>
                                <van-cell title="百分比" :value="`${item.baiFenBi} %`"></van-cell>
                            </template>
                        </van-cell-group>
                        <template #right>
                            <van-button type="danger" text="删除" class="delete" />
                        </template>
                    </van-swipe-cell>
                </div>
            </van-list>
        </div>
        <van-tabbar v-model="active">
            <van-tabbar-item icon="desktop-o">操作</van-tabbar-item>
            <van-tabbar-item icon="todo-list-o">查询</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
Vue.use(Vant);
export default {
    props: ["serialNumber", "testType"],
    data() {
        let that = this;
        return {
            loading: false,
            listLoading: false,
            listFinished: false,
            pickerDate: new Date(),
            showDatetimePicker: false,
            active: 1,
            obj: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
                serialNumber: that.serialNumber,
                testType: 0,
                search_Range: 0,
            },
            option1Value: parseInt(that.testType),
            option1: [
                { text: "水灰比", value: 0 },
                { text: "质量体积法", value: 1 },
                { text: "拌合物", value: 2 },
            ],
            option2Value: 0,
            option2: [
                { text: "全部", value: 0 },
                { text: "7天", value: 1 },
                { text: "30天", value: 2 },
            ],
            listData: [],
        };
    },
    methods: {
        deleteMateriel({ position, instance, name }) {
            let that = this;
            if (position == "right") {
                that.$dialog
                    .confirm({
                        message: "确定删除吗？",
                    })
                    .then(() => {
                        that.axios
                            .post("LLZ100_Result/Del", {
                                onlyInt: that.listData[name].id,
                            })
                            .then((response) => {
                                if (response.data.code == 101) {
                                    that.$notify({ type: "success", message: "删除成功" });
                                    that.listData.splice(name, 1);
                                    instance.close();
                                }
                            });
                    });
            }
        },
        handleChange() {
            let that = this;
            that.listLoading = true;
            that.listFinished = false;
            that.listData = [];
            that.obj.currentPage = 1;
            that.getList();
        },
        getList() {
            let that = this;
            that.obj.search_Range = that.option2Value;
            that.obj.testType = that.option1Value;
            that.axios.post("LLZ100_Result/GetList", that.obj).then((response) => {
                response.data.data.data.forEach((element) => {
                    that.listData.push(element);
                });
                that.listLoading = false;
                if (that.listData.length >= response.data.data.total) {
                    that.listFinished = true;
                } else {
                    that.obj.currentPage++;
                }
            });
        },
    },
    mounted() {

    },
};
</script>

<style scoped>
.card {
    border-radius: 5px;
    border: 1px solid #409eff;
    margin: 10px;
    padding: 5px;
}

.delete {
    height: 100%;
}
</style>
